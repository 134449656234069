import axios from "axios";
import { SET_CURRENT_USER } from "../constants/action-types";

export const setCurrentUser = (decoded: any) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setUser =
  (token: any) => (dispatch: (arg0: { type: any; payload: any }) => void) => {
    console.log(token)
    dispatch(setCurrentUser(token));
  };

export const logoutUser =
  () => (dispatch: (arg0: { type: any; payload: any }) => void) => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("persist:root");

    axios({ method: "post", url: "/api/users/logout", baseURL: "/" }).then(
      (res) => {
        dispatch(setCurrentUser({}));
        window.location.replace(res.data.callbackUrl);
      }
    );
  };
