import { Outlet, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withCookies } from "react-cookie";
import { AppDispatch } from "./store";
import { setUser, logoutUser } from "./actions/auth";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Login from "./components/Login";
import Users from "./components/Users";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import axios from "axios";

const themeDark = createTheme({
  palette: {
    primary: { main: "#002144" },
    mode: "dark",
  },
});

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const auth = useSelector((state: { auth: any }) => state.auth);

  useEffect(() => {
    try {
      axios({
        method: "post",
        url: "/api/users/validate-token",
        baseURL: "/",
      })
        .then((response) => {
          dispatch(setUser(response.data.user));
        })
        .catch((error) => {
          if (error.response) {
            if ([400, 403].includes(error.response.status)) {
              dispatch(logoutUser());
            } else {
              console.error("Unexpected error:", error);
            }
          } else {
            console.error("Network or unknown error:", error);
          }
        });
    } catch (error) {
      console.error("Error processing token:", error);
    }
  }, [dispatch]);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-1">
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route
              path="login"
              element={
                <ThemeProvider theme={themeDark}>
                  <Login />
                </ThemeProvider>
              }
            />
            {auth.user.role !== "STUDENT" && auth.user.role && (
              <Route path="users" element={<Users />} />
            )}
          </Route>
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default withCookies(App);
