import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../store";
import { logoutUser } from "../actions/auth";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Login } from "@mui/icons-material";

function TopNavbar() {
  const dispatch = useDispatch<AppDispatch>();
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      data-bs-theme="dark"
      expand="lg"
      sticky="top"
    >
      <Container>
        <Navbar.Brand href="/">Interline Reader</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink className="nav-link" to="/">
              Home
            </NavLink>
            {user?.role && user.role !== "STUDENT" && (
              <NavLink className="nav-link" to="/users">
                Users
              </NavLink>
            )}
            <NavLink className="nav-link" to="/about">
              About
            </NavLink>
          </Nav>
          {isAuthenticated ? (
            <Nav className="ms-auto">
              <NavDropdown title="Account" id="navbarDropdown" align="end">
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          ) : (
            <Nav className="ms-auto">
              <NavLink
                className="nav-link"
                to="/login"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Login style={{ marginRight: 5 }} fontSize="small" />
                Login
              </NavLink>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavbar;
