import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import store from "./store";

const themeLight = createTheme({
  palette: {
    primary: { main: "#002144" },
    mode: "light",
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <Box id="mainContainer" className="mainContainer">
          <ThemeProvider theme={themeLight}>
            <Router>
              <App />
            </Router>
          </ThemeProvider>
        </Box>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>
);
