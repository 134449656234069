import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SelectChangeEvent,
} from "@mui/material";

type User = {
  _id: string;
  username: string;
  oauthProvider: boolean;
  role: string;
  accessLevel: number;
};

const Users = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [newUsername, setNewUsername] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [newUserPassword, setNewUserPassword] = useState<string>("");

  const auth = useSelector((state: { auth: any }) => state.auth);

  useEffect(() => {
    axios.get("/api/users").then((resp) => setUsers(resp.data));
  }, []);

  function handleRoleChange(userId: string, event: SelectChangeEvent<string>) {
    const value = event.target.value as string;

    axios({
      method: "put",
      url: "/api/users/update-role",
      baseURL: "/",
      data: { role: value, userId },
    }).then(() => {
      setUsers(
        users.map((user) =>
          user._id === userId ? { ...user, role: value } : user
        )
      );
    });
  }

  function handleLevelChange(userId: string, event: SelectChangeEvent<number>) {
    const value = event.target.value as number;

    axios({
      method: "put",
      url: "/api/users/update-access-level",
      baseURL: "/",
      data: { accessLevel: value, userId },
    }).then(() => {
      setUsers(
        users.map((user) =>
          user._id === userId ? { ...user, accessLevel: value } : user
        )
      );
    });
  }

  function handleCreateUser() {
    setLoading(true);
    axios
      .post("/api/users/create-user", { username: newUsername })
      .then((resp) => {
        setNewUserPassword(resp.data.password);
        setUsers([...users, resp.data.newUser]);
        setOpenDialog(true);
      })
      .catch((error) => {
        alert(error.response?.data?.message || "Error creating user");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewUsername("");
    setNewUserPassword("");
  };

  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          User Management
        </Typography>

        <Box display="flex" gap={2} my={2}>
          <TextField
            label="New Username"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateUser}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Create User"}
          </Button>
        </Box>

        <TextField
          label="Search by Username"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          margin="normal"
          size="small"
        />
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Authentication</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Access Level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>
                    {user.oauthProvider ? "SAML/OAuth" : "Password"}
                  </TableCell>
                  <TableCell>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      sx={{ minWidth: 130 }}
                    >
                      <InputLabel>Role</InputLabel>
                      <Select
                        value={user.role}
                        label="Role"
                        onChange={(event) => handleRoleChange(user._id, event)}
                        disabled={auth.user.role === "INSTRUCTOR"}
                      >
                        <MenuItem value="STUDENT">Student</MenuItem>
                        <MenuItem value="INSTRUCTOR">Instructor</MenuItem>
                        <MenuItem value="ADMIN">Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      sx={{ minWidth: 160 }}
                    >
                      <InputLabel>Access Level</InputLabel>
                      <Select
                        value={user.accessLevel}
                        label="Access Level"
                        onChange={(event) => handleLevelChange(user._id, event)}
                        disabled={auth.user.role === "INSTRUCTOR"}
                      >
                        <MenuItem value={0}>All</MenuItem>
                        <MenuItem value={1}>KORN 351</MenuItem>
                        <MenuItem value={2}>KORN 410</MenuItem>
                        <MenuItem value={3}>KORN 420</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>User Created Successfully!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">
              <strong>Username:</strong> {newUsername}
            </Typography>
            <Typography variant="body1">
              <strong>Password:</strong> {newUserPassword}
            </Typography>
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              Please make sure to note this password securely. For security
              reasons, it will not be shown again once you close this dialog,
              unless a reset occurs.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Users;
