import React, { Component } from "react";
import {
  Button,
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Divider,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { withCookies, Cookies } from "react-cookie";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import VaseFlower from "../assets/flowerVase.jpg";
import UBCLogo from "../assets/ubc-logo.png";
import axios from "axios";

interface Auth {
  isAuthenticated: boolean;
}

interface LandingProps {
  auth: Auth;
  cookies: Cookies;
  dispatch: Function;
}

interface LandingState {
  username: string;
  password: string;
  showPassword: boolean;
  loading: boolean;
}

class Login extends Component<LandingProps, LandingState> {
  static propTypes = {
    auth: PropTypes.shape({
      isAuthenticated: PropTypes.bool.isRequired,
    }).isRequired,
  };

  state = {
    username: "",
    password: "",
    showPassword: false,
    loading: false,
  };

  loginWithCWL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const relayState = urlParams.get("relayState") || "";
    window.location.href = `${window.location.origin}/saml/auth?relayState=${relayState}`;
  };

  handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: e.target.value });
  };

  handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.target.value });
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  handleLogin = async () => {
    const { username, password } = this.state;
    this.setState({ loading: true });

    try {
      if (!username || !password) return;

      await axios.post("/api/users/login", {
        username,
        password,
      });

      const urlParams = new URLSearchParams(window.location.search);
      const relayState = urlParams.get("relayState") || "";
      window.location.href = `${window.location.origin}/${relayState}`;
    } catch (error) {
      alert("Request failed: Incorrect Login!");
    } finally {
      this.setState({ loading: false });
    }
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      this.handleLogin();
    }
  };

  render() {
    const { username, password, showPassword, loading } = this.state;

    return (
      <div className="relative bg-black">
        <div
          className="absolute inset-0 opacity-40"
          style={{
            backgroundImage: `url(${VaseFlower})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="relative flex flex-col min-h-screen items-center text-white z-10">
          <div className="flex justify-center items-center flex-col bg-dark sm:max-w-xl md:max-w-2xl xl:max-w-5xl rounded-xl space-y-4 shadow-xl mt-12 mx-4 p-4 md:p-8">
            <h1 className="text-3xl text-center md:text-3xl lg:text-4xl text-white mt-2">
              Interline Reader
            </h1>
            <div className="font-bold font-roboto flex flex-col justify-center color-white">
              <Button
                size="large"
                className="mt-2 mb-4"
                onClick={this.loginWithCWL}
                variant="contained"
                startIcon={
                  <img
                    src={UBCLogo}
                    alt="UBC Logo"
                    style={{ height: 20, width: 15 }}
                  />
                }
              >
                Continue with UBC CWL
              </Button>
              <Box marginBottom={2}>
                <Divider textAlign="center">OR</Divider>
              </Box>

              <Alert severity="info">
                Pre-registered account required for non-CWL login!
              </Alert>

              <TextField
                fullWidth
                variant="outlined"
                label="Username"
                value={username}
                onChange={this.handleUsernameChange}
                onKeyDown={this.handleKeyDown}
                className="mt-4 mb-3"
                size="small"
                InputProps={{
                  style: { color: "white" },
                }}
                InputLabelProps={{
                  style: { color: "white" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: 2,
                    },
                    "&:hover fieldset": {
                      borderColor: "lightgray",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "lightgray",
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type={showPassword ? "text" : "password"}
                label="Password"
                value={password}
                onChange={this.handlePasswordChange}
                onKeyDown={this.handleKeyDown}
                className="mb-4"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                        className="text-white"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { color: "white" },
                }}
                InputLabelProps={{
                  style: { color: "white" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: 2,
                    },
                    "&:hover fieldset": {
                      borderColor: "lightgray",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "lightgray",
                    },
                  },
                }}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={this.handleLogin}
                disabled={loading}
                className="mb-2"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { auth: Auth }) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withCookies(Login));
