import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import logo from "../assets/ILReader_Logo-with-tagline2.png";
import {
  Box,
  IconButton,
  CircularProgress,
  Paper,
  Tooltip,
  Button,
} from "@mui/material";
import {
  BLANK_RICH_TEXT,
  ReadOnlyEditor,
  RichTextEditor,
  withReact,
  withHistory,
  createEditor,
} from "interline-slate";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import { connect, useSelector } from "react-redux";

const About = (props: any) => {
  const auth = useSelector((state: { auth: any }) => state.auth);

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(BLANK_RICH_TEXT);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const name = "Hub_about";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/editable/${name}`);
        if (response.data && Array.isArray(response.data.data)) {
          setText(response.data.data);
        } else {
          setText(BLANK_RICH_TEXT);
        }
      } catch (error) {
        console.error(`Error fetching ${name} data:`, error);
        setText(BLANK_RICH_TEXT);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [props.cookies, props.auth]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await axios.post("/api/editable", { name, data: text });
      setIsEditing(false);
    } catch (error) {
      console.error(`Error saving ${name} data:`, error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <div className="w-full">
        <div className="p-8 flex justify-center">
          <img
            className="w-full max-w-[400px] md:max-w-[500px] lg:max-w-[800px]"
            src={logo}
            alt="Interline Reader"
          />
        </div>
      </div>

      <div className="max-w-5xl mx-auto px-6 py-10">
        <div className="">
          {loading ? (
            <Box sx={{ textAlign: "center", padding: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {isEditing ? (
                <RichTextEditor
                  id="terminology-rte"
                  initialValue={text}
                  onChange={setText}
                  editor={editor}
                  MUI={require("@mui/material")}
                />
              ) : (
                <ReadOnlyEditor
                  value={text}
                  editor={editor}
                  MUI={require("@mui/material")}
                />
              )}
              {auth.user.role === "ADMIN" && (
                <Paper
                  variant="outlined"
                  sx={{ padding: 1, textAlign: "center" }}
                >
                  <Tooltip title={isEditing ? "Quit Editor" : "Edit Page"}>
                    <IconButton
                      aria-label={`Edit ${name}`}
                      onClick={handleEditClick}
                      disabled={saving}
                    >
                      {isEditing ? <EditOffIcon /> : <EditIcon />}
                    </IconButton>
                  </Tooltip>
                  {isEditing && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      disabled={saving}
                      sx={{ marginLeft: 2 }}
                    >
                      {saving ? "Saving..." : "Save"}
                    </Button>
                  )}
                </Paper>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { auth: any }) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(About);
