import isEmpty from "../utils/is-empty";
import { SET_CURRENT_USER } from "../constants/action-types";

interface User {
  [key: string]: any; 
}

interface AuthState {
  isAuthenticated: boolean;
  user: User | Record<string, never>;
}

interface SetCurrentUserAction {
  type: typeof SET_CURRENT_USER;
  payload: User | Record<string, never>;
}

type AuthAction = SetCurrentUserAction;

const initialState: AuthState = {
  isAuthenticated: false,
  user: {},
};

const authReducer = (state: AuthState = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;